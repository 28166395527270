import Html2Canvas from 'html2canvas'

export function injectMathJax() {
  if (!window.MathJax) {
    // const script = document.createElement('script')
    // script.src =
    //   'https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-chtml.js'
    // script.async = true
    // document.head.appendChild(script)
    const scriptTexChtml = document.createElement('script')
    scriptTexChtml.src =
    'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS-MML_HTMLorMML'
    document.head.appendChild(scriptTexChtml)
  }
}

export function initMathJax(options = {}, callback) {
  injectMathJax()
  // // The default global configuration
  // const defaultConfig = {
  //   tex: {
  //     inlineMath: [['$', '$'], ['\\(', '\\)']],
  //     displayMath: [['$$', '$$']],
  //     processEnvironments: true,
  //     processRefs: true,
  //   },
  //   options: {
  //     skipHtmlTags: ['noscript', 'style', 'textarea', 'pre', 'code'],
  //     ignoreHtmlClass: 'tex2jax_ignore',
  //   },
  //   startup: {
  //     pageReady: () => {
  //       callback && callback()
  //     },
  //   },
  //   svg: {
  //     fontCache: 'global',
  //   },
  // }
  // // Merge configuration
  // const mergeConfig = Object.assign({}, defaultConfig, options)
  // window.MathJax = mergeConfig
}

// assume array of ref contains
export const renderMathMl = (arrayOfRef) => {
  const MathJax = window.MathJax
  if (MathJax) {
    // console.log(MathJax)
    arrayOfRef.forEach(async (ref) => {
      // const mathHtml = ref.getElementsByTagName('math')
      // const mjx = ref.getElementsByTagName('mjx-container')
      // if (!mjx.length) {
      //   mathHtml.forEach(math => {
      //     ref.appendChild(MathJax.mathml2chtml(math.outerHTML))
      //     MathJax.startup.document.clear()
      //     MathJax.startup.document.updateDocument()
      //     math.innerHTML = ''
      //   })
      // }
      MathJax.Hub.Config({
        config: ['MMLorHTML.js'],
        jax: ['input/TeX', 'input/MathML', 'output/HTML-CSS', 'output/NativeMML', 'output/PreviewHTML'],
        extensions: ['tex2jax.js', 'mml2jax.js', 'MathMenu.js', 'MathZoom.js', 'fast-preview.js', 'AssistiveMML.js', 'a11y/accessibility-menu.js'],
        TeX: {
          extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js'],
        },
        tex2jax: {
          inlineMath: [['$', '$'], ['(', ')']],
          displayMath: [['$$', '$$'], ['[', ']']],
          processEscapes: true,
          processEnvironments: true,
        },
        displayAlign: 'center',
        'HTML-CSS': {
          styles: { '.MathJax_Display': { margin: 0 } },
          linebreaks: { automatic: true },
        },
      })
      MathJax.Hub.Queue([
        'Typeset',
        MathJax.Hub,
        ref,
      ])
      setTimeout(() => {
        MathJax.Hub.Queue(async () => {
          const equations = ref.getElementsByClassName('MathJax')
          for (let i = 0; i < equations.length; i++) {
            const equation = equations[i]
            const canvas = await Html2Canvas(equation, {
              width: 250,
              height: 250,
            })
            ref.appendChild(canvas)
          }
        })
      }, 2000)
    })
  }
}

export const renderTex2Chtml = async (arrayOfRef) => {
  const MathJax = window.MathJax
  if (MathJax) {
    // console.log(MathJax)
    arrayOfRef.forEach(async (ref) => {
      MathJax.Hub.Config({
        mml2jax: {
          inlineMath: [['$', '$'], ['(', ')']],
          displayMath: [['$$', '$$'], ['[', ']']],
          processEscapes: true,
          processEnvironments: true,
        },
        displayAlign: 'center',
        'HTML-CSS': {
          styles: { '.MathJax_Display': { margin: 0 } },
          linebreaks: { automatic: true },
        },
      })
      MathJax.Hub.Queue([
        'Typeset',
        MathJax.Hub,
        ref,
      ])

      const equations = ref.getElementsByClassName('MathJax')
      // console.log(equations)
      for (let i = 0; i < equations.length; i++) {
        const equation = equations[i]
        await Html2Canvas(equation)
      }
    })
  }
}
