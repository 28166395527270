const clearTag = (text) => {
  // const replaceTagRegex = /<([^'">]+|[^'">]+")*>/gim
  // console.log(text)
  // if (!text) return text
  // while (text.match(replaceTagRegex) !== null) {
  //   text = text.replace(text.match(replaceTagRegex)[0], '')
  // }
  // return text
  // return text
  if (!text) {
    return 'Empty'
  } else {
    return text.replace(/<[^>]*>/g, '')
  }
}

export { clearTag }
